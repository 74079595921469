import AnimatedButton from "@/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS_BULDINGS } from "./actions.constants";
import {
  decreaseAutoBoostersUser,
  decreaseCoins,
  decreasePearls,
} from "@/store/user/userSlice";
import { IActionsHouseContainerProps } from "./interfaces";
import {
  increaseAutoBoosters,
  selectHouseByName,
  upgradeHouse,
} from "@/store/island/islandSlice";
import { RootState } from "@/store";
import { isCanUpgrade } from "@/utils/houses/isCanUpgrade";
import { getCostToUpgrade } from "@/utils/getters/getPopUpDataByName";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import useClickSound, { SOUND } from "@/hooks/useClickSound";
import { increaseStep } from "@/store/tutorial/tutorialSlice";

import styles from "./styles.module.scss";

export default function ActionsHouseContainer({
  name,
}: IActionsHouseContainerProps) {
  const dispatch = useDispatch();
  const { quantity } = useSelector(
    (state: RootState) => state.user.balance.auto_boosters
  );
  const quantityBoosters = useSelector(
    (state: RootState) => state.user.balance.auto_boosters.quantity
  );
  const { isActive, step } = useSelector((state: RootState) => state.tutorial);
  const playUpgradeSound = useClickSound({ src: SOUND.UPGRADE });

  const [coin_revresh, coin, upgrade] = ACTIONS_BULDINGS;
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, name)
  );

  if (!house) return;

  const { ws } = useSelector((state: RootState) => state.sockets);

  const disabled = isCanUpgrade(house);

  const handleClickCoinRevresh = () => {
    dispatch(decreaseAutoBoostersUser(1));
    sendWsEvent(ws, WebSocketsClientEvents.AUTO_COLLECT, {
      house_type_name: house.house_type_name,
    });
    dispatch(increaseAutoBoosters({ name: house?.house_type_name }));
  };

  const onClickUpgrade = () => {
    playUpgradeSound();
    if (isActive && step == 4) {
      dispatch(increaseStep());
      sendWsEvent(ws, WebSocketsClientEvents.UPGRADE_HOUSE, {
        name: name,
        level: house && house.level + 1,
      });
      sendWsEvent(ws, WebSocketsClientEvents.TUTORIAL_HOUSE_BUILD, {
        house_type_name: name,
      });
      return;
    }
    sendWsEvent(ws, WebSocketsClientEvents.UPGRADE_HOUSE, {
      name: name,
      level: house && house.level + 1,
    });
    dispatch(upgradeHouse(name));
    const values = getCostToUpgrade(house?.level);
    dispatch(decreaseCoins(values.coins));
    dispatch(decreasePearls(values.pearls));
    if (isActive) {
      dispatch(increaseStep());
    }
  };

  const isUserHaveMoreBoosters = quantityBoosters <= 0;
  const isCanBeMoreBoosters = house.quantity_auto_boosters >= 1;

  return (
    <div
      className={`${styles.wrapper} ${
        step === 4 || step == 7 ? styles.wrapper__tutorial__active : ""
      }`}
    >
      <AnimatedButton
        onClick={handleClickCoinRevresh}
        key={coin_revresh.id}
        quantity={quantity}
        disabled={
          isCanBeMoreBoosters || isUserHaveMoreBoosters || house.level === 0
        }
        {...coin_revresh}
      />
      <AnimatedButton
        disabled={disabled}
        onClick={onClickUpgrade}
        key={upgrade.id}
        {...upgrade}
      />
    </div>
  );
}
