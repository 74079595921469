import { formatTime } from "@/utils/formatters/formatTime";
import { ITimeProps } from "./interfaces";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { selectHouseByName } from "@/store/island/islandSlice";
import lightCoin from "@/assets/icons/coin.png";
import darkCoin from "@/assets/icons/dark_coin.png";
import AnimatedButton from "@/components/Button";
import Image from "next/image";
import { HOUSES } from "@/store/island/island.mockData";
import { ACTIONS_BULDINGS } from "../Containers/ActionsHouseContainer/actions.constants";

import styles from "./styles.module.scss";

export default function Timer({
  isSelected,
  name,
  handleCollectResources,
}: ITimeProps) {
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, name)
  );
  const isCanCollect = house?.is_can_collect;
  const formattedTime = formatTime(
    house?.time_remaining_to_full_resources || 0
  );
  const coin = ACTIONS_BULDINGS[1];

  const boosters = house?.quantity_auto_boosters || 0;
  const value = house?.resource_amount_gold;

  if (!house) return null;

  const isSpecialHouse = house.house_type_name === HOUSES[0].house_type_name;

  
  return (
    <>
      {!isSelected &&
      house?.time_remaining_to_full_resources &&
      house?.time_remaining_to_full_resources > 0 ? (
        <div
          className={`${styles.timer__wrapper} ${
            boosters == 0 ? styles.timer__wrapper__disable_space : ""
          }`}
        >
          <span className={`${styles.timer}`}>{formattedTime}</span>
          {boosters > 0 && (
            <div className={styles.coins}>
              <Image
                src={boosters >= 1 ? lightCoin : darkCoin}
                alt="Coin 1"
                quality={100}
                width={16}
                className={styles.coin}
              />
              <Image
                src={boosters >= 2 ? lightCoin : darkCoin}
                alt="Coin 2"
                width={16}
                quality={100}
                className={styles.coin}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {!isSelected &&
            isCanCollect &&
            house?.level > 0 &&
            !isSpecialHouse && (
              <div className={styles.wrapper}>
                <AnimatedButton
                  onClick={handleCollectResources}
                  value={value}
                  key={coin.id}
                  {...coin}
                />
              </div>
            )}
          {!isSelected &&
            house?.level > 0 &&
            isSpecialHouse &&
            isCanCollect && (
              <div className={styles.wrapper}>
                <AnimatedButton
                  onClick={handleCollectResources}
                  value={value}
                  key={coin.id}
                  {...coin}
                />
              </div>
            )}
        </>
      )}
    </>
  );
}
