import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "next/image";
import { IButtonHouseProps } from "./interfaces";
import { IValue, usePopup } from "@/hooks/usePopUp";
import { getNameByImage } from "@/utils/getters/getNameByImage";
import {
  ACTIONS,
  getCostToUpgrade,
  getPopUpDataByNames,
} from "@/utils/getters/getPopUpDataByName";
import { RootState } from "@/store";
import { selectHouseByName } from "@/store/island/islandSlice";
import { isNotNeedPopUp } from "@/utils/popUp";
import Sprite from "@/components/Sprite";
import Fade from "@/components/Fade";
import {
  selectImagesByDirectory,
  selectOptionsByDirectory,
} from "@/store/app/appSlice";

import styles from "./styles.module.scss";

export default function Button({
  onClick,
  disabled,
  image,
  quantity,
  directory,
}: IButtonHouseProps) {
  const [isFading, setIsFading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { modal } = usePopup();
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, state.island.activeNameHouse)
  );

  const images = useSelector((state) =>
    selectImagesByDirectory(state, directory)
  );
  const options = useSelector((state) =>
    selectOptionsByDirectory(state, directory)
  );

  const cachedImages = useMemo(() => images, [images]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const name = getNameByImage(image.src);
    const COIN = "coin";

    if (isNotNeedPopUp(name)) {
      setIsFading(true);
      if (name == COIN) {
        setTimeout(() => {
          onClick();
        }, 1100);
        return;
      }
      onClick();
      return;
    }

    const { title, textButton, icon, text, values } = getPopUpDataByNames(name);

    if (values) {
      const levelHouse = house?.level;
      const newValues = getCostToUpgrade(levelHouse);

      const updatedValues: IValue = {
        textValue: values?.textValue || "",
        values: newValues,
      };

      if (levelHouse == 0) {
        const data = getPopUpDataByNames(ACTIONS.BUILD_HOUSE);

        modal(
          data.title,
          data.textButton,
          data.text,
          data.icon,
          data.values
        ).then((answer) => {
          if (answer) {
            onClick();
            setIsFading(true);
          }
        });
        return;
      }

      modal(title, textButton, text, icon, updatedValues).then((answer) => {
        if (answer) {
          onClick();
          setIsFading(true);
        }
      });
    }

    if (!values) {
      modal(title, textButton, text, icon, values).then((answer) => {
        if (answer) {
          onClick();
          setIsFading(true);
        }
      });
    }
  };

  const handleFadeEnd = () => {
    setIsAnimating(true);
    setIsFading(false);
  };

  const handleEndAnimation = () => {
    setIsAnimating(false);
  };

  return (
    <Fade trigger={isFading} onFadeComplete={handleFadeEnd}>
      {isAnimating && options && cachedImages.length > 0 ? (
        <Sprite
          key={directory}
          onEnd={handleEndAnimation}
          images={cachedImages}
          options={options}
        />
      ) : (
        <button
          disabled={disabled}
          onClick={handleClick}
          className={`${styles.button} ${disabled ? styles.disabled : ""}`}
        >
          {quantity && quantity > 0 ? (
            <span className={styles.button__quantity}>{quantity}</span>
          ) : null}
          <Image
            className={styles.button__image}
            src={image}
            alt="The picture of coin"
            width={35}
            height={35}
          />
        </button>
      )}
    </Fade>
  );
}
