import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { Application } from "pixi.js";
import { loadBackground } from "@/utils/pixi/loadbackground";
import HouseCard from "@/components/Cards/HouseCard";
import ShipCard from "@/components/Cards/ShipCard";
import { SHIP_COORDINATES, SHIP_IMAGE } from "@/constants/buildings";
import background from "@/assets/screens/menu/island_background.jpg";
import {
  setApp,
  setCanvas,
  setIsLoading,
} from "@/store/app/appSlice";
import { selectHouseByName, setIsTraveling } from "@/store/island/islandSlice";
import { HOUSES } from "@/store/island/island.mockData";

import styles from "./styles.module.scss";
import { getLocalStorageItem } from "@/utils/localStorage";
import { TUTORIALS } from "@/constants/localStorage";
import {
  increaseStep,
  setActiveTutorial,
  setIsActive,
} from "@/store/tutorial/tutorialSlice";
import { PRIZE } from "@/constants/cookies";

export default function MenuCanvas() {
  const dispatch = useDispatch();
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const appRef = useRef<Application | null>(null);
  const {webApp} = useSelector((state: RootState) => state.app);
  const { houses } = useSelector((state: RootState) => state.island);
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, HOUSES[0].house_type_name)
  );
  const { ws } = useSelector((state: RootState) => state.sockets);
  const [showShip, setShowShip] = useState(false);
  const [isShipVisible, setIsShipVisible] = useState(false);

  useEffect(() => {
    if (getLocalStorageItem(PRIZE)) return;
    // if(!webApp) return;

    const mainTutorial = getLocalStorageItem(TUTORIALS.MAIN);

    if (!mainTutorial) {
      dispatch(setActiveTutorial(TUTORIALS.MAIN));
      dispatch(setIsActive(true));
      dispatch(increaseStep());
    } else {
      dispatch(setIsActive(false));
    }
  }, []);



  useEffect(() => {
    (async () => {

      setTimeout(() => {
        if (ws?.readyState === 1) {
          dispatch(setIsLoading(false));
        }
      }, 400);

      if (canvasRef.current) {
        dispatch(setCanvas(canvasRef.current));
      }

      return () => {
        if (appRef.current) {
          appRef.current.destroy(true, { children: true });
          appRef.current = null; 
        }
      };
    })();
  }, [dispatch]);

  const isTraveling = useMemo(
    () =>
      house?.time_remaining_to_full_resources !== undefined &&
      house.time_remaining_to_full_resources > 0,
    [house]
  );

  useEffect(() => {
    if (isTraveling) {
      setShowShip(true);

      const timer = setTimeout(() => {
        setIsShipVisible(false);
      }, 1100);

      return () => clearTimeout(timer);
    } else {
      setShowShip(true);
      setIsShipVisible(true);
    }

    dispatch(setIsTraveling(!(showShip && isShipVisible)));
  }, [isTraveling]);

  if (houses.length < 1) return null;

  return (
    <div className={styles.canvas}>
      {houses.map((house) => (
        <HouseCard
          name={house.house_type_name}
          key={house.house_type_name}
          images={house.images}
          coordinates={house.coordinates}
        />
      ))}

      {showShip && isShipVisible && (
        <ShipCard image={SHIP_IMAGE} coordinates={SHIP_COORDINATES} />
      )}
    </div>
  );
}
