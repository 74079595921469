import { useEffect, useRef } from "react";

import stylesModal from "@/components/Popup/styles.module.scss";
import stylesButton from "@/components/ActionButton/styles.module.scss";

type callback = () => void;

export const useOutsideClick = (callback: callback) => {
  const ref: any = useRef();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const container = document.querySelector(
        `.${stylesModal.modal__content}`
      ) as HTMLElement;


      if (
        (container && container.contains(target)) ||
        target?.className == stylesModal.modal__close ||
        target?.className == stylesButton.button_action
      ) {
        return;
      }

      if (ref?.current && !ref.current?.contains(event?.target)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback]);

  return ref;
};
