import React, { useMemo, useEffect } from "react";
import Image from "next/image";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import { IHouseCardProps } from "./interface";
import { useDispatch, useSelector } from "react-redux";
import ActionsHouseContainer from "@/components/Containers/ActionsHouseContainer";
import {
  selectHouseByName,
  setCanCollect,
  setIsTraveling,
  setNameActiveHouse,
} from "@/store/island/islandSlice";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import { startHouseTimers } from "@/utils/houses/processData";
import { generateStylesByCoordinates } from "@/utils/houses/generateStylesByCoordinates";
import { HOUSES } from "@/store/island/island.mockData";
import ActionsWharfContainer from "@/components/Containers/ActionsWharfContainer";
import Timer from "@/components/Time";
import { RootState } from "@/store";
import { increaseStep, setRef } from "@/store/tutorial/tutorialSlice";

import styles from "./styles.module.scss";
import useClickSound, { SOUND } from "@/hooks/useClickSound";

export default function HouseCard({
  images,
  coordinates,
  name,
}: IHouseCardProps) {
  const [isSelected, setIsSelected] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(images.default);
  const { isActive, step } = useSelector((state: RootState) => state.tutorial);
  const dispatch = useDispatch();
  const playSound = useClickSound();
  const playСollectSound = useClickSound({ src: SOUND.COLLECT });
  const { ws } = useSelector((state: RootState) => state.sockets);
  const house = useSelector((state: RootState) =>
    selectHouseByName(state, name)
  );

  const stylesFort =
    house?.house_type_name == HOUSES[0].house_type_name
      ? isActive
        ? { zIndex: 2550 }
        : { zIndex: 55 }
      : { zIndex: 50 };

  const stylesInline = useMemo(
    () =>
      generateStylesByCoordinates(
        isSelected ? coordinates.selected : coordinates.notSelected
      ),
    [isSelected, coordinates]
  );

  useEffect(() => {
    if (isSelected) {
      setCurrentImage(images.select);
    } else {
      setCurrentImage(images.default);
    }
  }, [isSelected, images]);

  useEffect(() => {
    if (
      (house?.house_type_name === HOUSES[0].house_type_name ||
        house?.house_type_name === HOUSES[4].house_type_name) &&
      containerRef
    ) {
      dispatch(
        setRef({ name: house.house_type_name, ref: containerRef.current })
      );
    }
    if (step == 6 && house?.house_type_name == HOUSES[4].house_type_name) {
      setIsSelected((prev) => !prev);
    }
    if (step == 7 && house?.house_type_name == HOUSES[4].house_type_name) {
      setIsSelected((prev) => !prev);
    }
  }, [isActive, step]);

  const handleClickOnHouse = () => {
    if (!house) return;
    playSound();
    if (house.level == 0) {
      dispatch(setNameActiveHouse(name));
      setIsSelected((prev) => !prev);
      if (isActive) {
        dispatch(increaseStep());
      }
      return;
    }

    if (
      house.house_type_name == HOUSES[0].house_type_name &&
      !house.is_can_collect
    ) {
      dispatch(setNameActiveHouse(name));
      setIsSelected((prev) => !prev);
      return;
    }

    if (house?.time_remaining_to_full_resources <= 0) {
      return;
    }

    dispatch(setNameActiveHouse(name));
    setIsSelected((prev) => !prev);
  };

  const containerRef = useOutsideClick(() => {
    if (isSelected) {
      setIsSelected(false);
      setCurrentImage(images.default);
    }
  });

  const handleCollectResources = () => {
    if (!house || house?.time_remaining_to_full_resources > 0) return;
    playСollectSound();
    sendWsEvent(ws, WebSocketsClientEvents.COLLECT_RESOURCES_GOLD, {
      name: house.house_type_name,
      resources_amount_gold: house.resource_amount_gold,
      resources_amount_pearl: house.resource_amount_pearl,
    });

    dispatch(setCanCollect({ name: house.house_type_name, canCollect: false }));
    if (ws) {
      dispatch(startHouseTimers([house], ws) as any);
    }

    if (isActive) {
      dispatch(increaseStep());
    }
  };

  const callbackTravelClick = () => {
    dispatch(setIsTraveling(true));
  };

  if (!stylesInline) return null;

  const dynamicClassName = house?.house_type_name
    ? styles[house.house_type_name]
    : "";

  return (
    <div
      style={{
        ...stylesInline,
      }}
      ref={containerRef}
      className={`${styles.card} ${dynamicClassName}`}
    >
      <Timer
        name={name}
        handleCollectResources={handleCollectResources}
        isSelected={isSelected}
      />

      {isSelected && name !== HOUSES[0].house_type_name && (
        <ActionsHouseContainer name={name} />
      )}

      {isSelected && name == HOUSES[0].house_type_name && (
        <ActionsWharfContainer callbackTravelClick={callbackTravelClick} />
      )}

      <Image
        className={styles.card__image}
        quality={100}
        src={currentImage}
        alt={`picture of ${name}`}
      />
      <div
        style={stylesFort}
        onClick={handleClickOnHouse}
        className={styles.card__clickable_zone}
      />
    </div>
  );
}
