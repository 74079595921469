import AnimatedButton from "@/components/Button";
import { ACTIONS_WHARF } from "./actions.constants";
import { IActionsShipContainerProps } from "./interfaces";

import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import {
  selectHouseByName,
  selectIsBoostActive,
  upgradeHouse,
} from "@/store/island/islandSlice";
import { ACTIONS_BULDINGS } from "../ActionsHouseContainer/actions.constants";
import { HOUSES } from "@/store/island/island.mockData";
import { isCanUpgrade } from "@/utils/houses/isCanUpgrade";
import { getCostToUpgrade } from "@/utils/getters/getPopUpDataByName";
import {
  decreaseAutoBoostersUser,
  decreaseCoins,
} from "@/store/user/userSlice";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import { increaseStep } from "@/store/tutorial/tutorialSlice";
import { use, useMemo } from "react";
import useClickSound, { SOUND } from "@/hooks/useClickSound";

export default function ActionsWharfContainer({
  callbackTravelClick,
}: IActionsShipContainerProps) {
  const dispatch = useDispatch();
  const name = HOUSES[0].house_type_name;
  const [auto_deaprture, departure] = ACTIONS_WHARF;
  const { isActive, step } = useSelector((state: RootState) => state.tutorial);
  const { quantity } = useSelector(
    (state: RootState) => state.user.balance.auto_boosters
  );
  const quantityBoosters = useSelector(
    (state: RootState) => state.user.balance.auto_boosters.quantity
  );
  const playShipTraveSound = useClickSound({ src: SOUND.SHIP });
  const playUpgradeSound = useClickSound({ src: SOUND.UPGRADE });

  const isBoosted = useSelector(selectIsBoostActive);
  const upgrade = ACTIONS_BULDINGS[2];
  const { ws } = useSelector((state: RootState) => state.sockets);

  const house = useSelector((state: RootState) =>
    selectHouseByName(state, name)
  );
  if (!house) return;

  const disabled = isCanUpgrade(house);

  const handleClickAutoDeparture = () => {
    playShipTraveSound();
    dispatch(decreaseAutoBoostersUser(1));
    sendWsEvent(ws, WebSocketsClientEvents.SHIP_SENT_TRAVEL, {
      boost_used: isBoosted,
    });
    sendWsEvent(ws, WebSocketsClientEvents.AUTO_COLLECT, {
      house_type_name: house.house_type_name,
    });
    sendWsEvent(ws, WebSocketsClientEvents.ACUTALIZED_DATA, {});
    callbackTravelClick();
  };

  const handleClickDeparture = () => {
    playShipTraveSound();
    sendWsEvent(ws, WebSocketsClientEvents.SHIP_SENT_TRAVEL, {
      boost_used: isBoosted,
    });
    callbackTravelClick();
    if (isActive) {
      dispatch(increaseStep());
    }
  };

  const onClickUpgrade = () => {
    playUpgradeSound();
    sendWsEvent(ws, WebSocketsClientEvents.UPGRADE_HOUSE, {
      name: name,
      level: house && house.level + 1,
    });
    dispatch(upgradeHouse(name));
    const values = getCostToUpgrade(house?.level);
    dispatch(decreaseCoins(values.coins));
    dispatch(decreaseCoins(values.pearls));
    if (isActive) {
      dispatch(increaseStep());
    }
  };

  const isCanBeMoreBoosters = house.quantity_auto_boosters >= 2 || false;
  const isUserHaveMoreBoosters = quantityBoosters <= 0;
  
  const isTraveling = useMemo(
    () =>
      house?.time_remaining_to_full_resources !== undefined &&
      house.time_remaining_to_full_resources > 0,
    [house]
  );

  return (
    <div
      className={`${styles.wrapper} ${
        step == 10 ? styles.wrapper__tutorial__active : ""
      } ${step == 9 ? styles.wrapper__tutorial__active__second : ""}`}
    >
      <AnimatedButton
        onClick={handleClickAutoDeparture}
        key={auto_deaprture.id}
        disabled={isCanBeMoreBoosters || isUserHaveMoreBoosters || house.level === 0}
        {...auto_deaprture}
        quantity={quantity}
      />
      <AnimatedButton
        onClick={handleClickDeparture}
        key={departure.id}
        disabled={isTraveling || house.level === 0}
        {...departure}
      />
      <AnimatedButton
        disabled={disabled}
        onClick={onClickUpgrade}
        key={upgrade.id}
        {...upgrade}
      />
    </div>
  );
}
